module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Christa Burch","short_name":"Christa Burch","start_url":"/","background_color":"hsl(290, 34%, 10%)","theme_color":"hsl(290, 34%, 10%)","display":"standalone","icon":"src/assets/burch.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f532191a91eb5febb3a7c1aca84cd4e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
